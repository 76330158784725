import { createGlobalStyle } from 'styled-components'

export const GlobalBackgroundColors = {
  bgPrimary: "#0B1E30",
  bgSecondary: "#FFFFFF",
  bgTertiary: `#42CEE5`,
  bgGreyDark: "#383e40",
  bgGreyLight: "#f8f8f8",
  bgContentLight: `#F67879`,
  bgContentLightest: `#FDDAD2`,
  bgColorApp: `#f7f9fc`,
  bgColorHeader: `#ffffff`,
  bgColorMenu: `#f7f9fc`,
  bgColorMain: `#ffffff`,
  bgColorFrame: `#f7f9fb`,
  bgColorTextFound: `#f39c12`,
  bgContact: `#233752`
}

export const GlobalColors = {
  colorMenuPrimary: `#FFFFFF`,
  colorMenuSecondary: `#1D1D1B`,
  colorBorder: `#e7ecf1`,
  colorBorderDark: `#9da1a4`,
  colorPrimary: `#FFFFFF`,
  colorPrimaryLight: `#f1c40f`,
  colorPrimaryDark: `#e67e22`,
  colorSecondary: `#233752`,
  colorTertiary: `#BEA376`,
  colorSuccess: `#20bf6b`,
  colorDanger: `#eb3b5a`,
  colorDangerDark: `#c5314b`,
  colorWarning: `#f7b731`,
  colorInfo: `#8854d0`,
  colorLight: `#f7f9fc`,
  colorDark: `#4b6584`,
  colorLinkHover: `#42CEE5`,
  colorShadowRGB: `75, 101, 132`,
  colorGold: `#BEA376`
}

export const GlobalFonts = {
  fontPrimary: 'HelveticaNeue',
  fontSecondary: 'BebasNeueRegular',
  fontTertiary: 'TheanoDidotRegular',
  fontLight: 'HelveticaNeueLight',
  fontRegular: 'HelveticaNeue',
  fontMedium: 'HelveticaNeueMedium',
  fontBold: 'HelveticaNeueMedium',
}

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    overflow: auto !important;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, p, button { margin: 0; padding: 0; }
  ul { list-style: none; }
  button { background: transparent; border: 0; outline: 0 }

  body {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background: ${GlobalBackgroundColors.bgPrimary};
    overscroll-behavior: none;
  }
  #root{
    height: 100vh;
  }
  .router{
    position: unset !important;
  }
  .wrapper{
    height: 100%;
  }
  *:focus {
    outline: none;
  }
  .cont-page,
  .alto-100,
  .full-height{
    height: 100%;
  }
  .full-width{
    width: 100%;
  }
  .display-flex{
    display: flex;
  }
  
  .padding-top {
    padding-top: 50px;
  }
  .padding-top-30 {
    padding-top: 30px;
  }
  
  .not-allowed {cursor: not-allowed;}
  
  #app {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    min-height: 100vh;
    padding-bottom: 10px;
  }
  .container.is-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .columns {
    margin-top: 0 !important;
  }
  .columns:not(:last-child) {
    margin-bottom: 0 !important;
  }
  .columns-paddign-no-global{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .columns-paddign-no{
    @media(min-width: 300px) and (max-width: 768px){
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
  .columns-margin-side-no{
    margin-left: 0px !important;
    margin-right: 0px !important
  }
  .global-columns-margin-no{
    margin: 0px !important;
  }
  .global-columns-paddign-no{
    padding: 0px !important;
  }
  .overflow-unset{
    overflow: unset !important;
  }
  
  .flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-center-important{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .transition-group {
    flex: 1;
    position: relative;
  }
  
  .router {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
    
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 350ms ease-in;
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    .mobile-column-row-reverse {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media(min-width: 300px) and (max-width: 1024px){
    .columns {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .columns:last-child {
      margin-bottom: 0;
    }
    .padding-no-responsive {
      padding: 0 !important;
    }
  }
  @media(max-width: 768px){
    .column.is-5-mobile,
    .column.is-6-mobile{
      padding-top: .75rem;
      float: left;
    }
  }
  
  @media (min-width: 1023px){
    .display-mobile{
      display: flex !important;
    }
  }
  @media (max-width: 1023px){
    .columns-responsive{
      
    }
    .no-display-mobile{
      display: none !important;
    }
  }
  @media (min-width: 1024px){
    .no-display-desk{
      display: none;
    }
  }
`
