import { createGlobalStyle } from 'styled-components'

import BebasNeueRegularEot from '../fonts/BebasNeueRegular.eot';
import BebasNeueRegularTtf from '../fonts/BebasNeueRegular.ttf';
import BebasNeueRegularWoff from '../fonts/BebasNeueRegular.woff';
import BebasNeueRegularWoff2 from '../fonts/BebasNeueRegular.woff2';
import BebasNeueRegularSvg from '../fonts/BebasNeueRegular.svg';

import HelveticaNeueLightEot from '../fonts/HelveticaNeueLight.eot';
import HelveticaNeueLightTtf from '../fonts/HelveticaNeueLight.ttf';
import HelveticaNeueLightWoff from '../fonts/HelveticaNeueLight.woff';
import HelveticaNeueLightWoff2 from '../fonts/HelveticaNeueLight.woff2';
import HelveticaNeueLightSvg from '../fonts/HelveticaNeueLight.svg';

import HelveticaNeueEot from '../fonts/HelveticaNeue.eot';
import HelveticaNeueTtf from '../fonts/HelveticaNeue.ttf';
import HelveticaNeueWoff from '../fonts/HelveticaNeue.woff';
import HelveticaNeueWoff2 from '../fonts/HelveticaNeue.woff2';
import HelveticaNeueSvg from '../fonts/HelveticaNeue.svg';

import HelveticaNeueMediumEot from '../fonts/HelveticaNeueMedium.eot';
import HelveticaNeueMediumTtf from '../fonts/HelveticaNeueMedium.ttf';
import HelveticaNeueMediumWoff from '../fonts/HelveticaNeueMedium.woff';
import HelveticaNeueMediumWoff2 from '../fonts/HelveticaNeueMedium.woff2';
import HelveticaNeueMediumSvg from '../fonts/HelveticaNeueMedium.svg';

import TheanoDidotRegularEot from '../fonts/TheanoDidotRegular.eot';
import TheanoDidotRegularTtf from '../fonts/TheanoDidotRegular.ttf';
import TheanoDidotRegularWoff from '../fonts/TheanoDidotRegular.woff';
import TheanoDidotRegularWoff2 from '../fonts/TheanoDidotRegular.woff2';
import TheanoDidotRegularSvg from '../fonts/TheanoDidotRegular.svg';

export const GlobalFonts = createGlobalStyle`
  
  @font-face {
    font-family: 'BebasNeueRegular';
    src: url(${BebasNeueRegularEot});
    src: local('BebasNeueRegular'), local('BebasNeueRegular'),
        url(${BebasNeueRegularWoff2}) format('woff2'),
        url(${BebasNeueRegularWoff}) format('woff'),
        url(${BebasNeueRegularTtf}) format('truetype'),
        url('${BebasNeueRegularSvg}#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueLight';
    src: url(${HelveticaNeueLightEot});
    src: local('HelveticaNeueLight'), local('HelveticaNeueLight'),
        url(${HelveticaNeueLightWoff2}) format('woff2'),
        url(${HelveticaNeueLightWoff}) format('woff'),
        url(${HelveticaNeueLightTtf}) format('truetype'),
        url('${HelveticaNeueLightSvg}#HelveticaNeueLight') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueLight';
    src: url(${HelveticaNeueEot});
    src: local('HelveticaNeue'), local('HelveticaNeue'),
        url(${HelveticaNeueWoff2}) format('woff2'),
        url(${HelveticaNeueWoff}) format('woff'),
        url(${HelveticaNeueTtf}) format('truetype'),
        url('${HelveticaNeueSvg}#HelveticaNeue') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueMedium';
    src: url(${HelveticaNeueMediumEot});
    src: local('HelveticaNeueMedium'), local('HelveticaNeueMedium'),
        url(${HelveticaNeueMediumWoff2}) format('woff2'),
        url(${HelveticaNeueMediumWoff}) format('woff'),
        url(${HelveticaNeueMediumTtf}) format('truetype'),
        url('${HelveticaNeueMediumSvg}#HelveticaNeueMedium') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TheanoDidotRegular';
    src: url(${TheanoDidotRegularEot});
    src: url(${TheanoDidotRegularEot}) format('embedded-opentype'),
    url(${TheanoDidotRegularWoff2}) format('woff2'),
    url(${TheanoDidotRegularWoff}) format('woff'),
    url(${TheanoDidotRegularTtf}) format('truetype'),
    url('${TheanoDidotRegularSvg}#TheanoDidotRegular') format('svg');
  }

`
